import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Container, Grid, IconButton, Tooltip } from "@mui/material";
import logo from "assets/footer-logo.png";
import { Link } from "react-router-dom";
export default function Default() {
  function sendEmail() {
    console.log("d");
    const email = "farrukhclosetofficial@gmail.com"; // Replace with your email address
    window.location.href = `mailto:${email}`;
  }
  return (
    <Box
      sx={{
        backgroundColor: "rgb(0,0,0,0.1)",
        width: "100%",
        paddingTop: "10px",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box>
              <img
                style={{
                  height: "100px",
                  width: "220px",
                }}
                src={logo}
                alt={logo}
              />
            </Box>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "#525252",
                padding: "10px 20px",
              }}
            >
              Home
            </Link>
            <br />
            <Link
              to="/product"
              style={{
                textDecoration: "none",
                color: "#525252",
                padding: "10px 20px",
              }}
            >
              Products
            </Link>
            <br />
            <Link
              to="/contactus"
              style={{
                textDecoration: "none",
                color: "#525252",
                padding: "10px 20px",
              }}
            >
              Contact
            </Link>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
          >
            <a
              href="https://www.instagram.com/farrukhclosetofficial?igsh=MWpoZmoxeTRpOHFpMA=="
              target="blank"
            >
              <Tooltip title="Instagram">
                <IconButton>
                  <InstagramIcon />
                </IconButton>
              </Tooltip>
            </a>
            <Tooltip title="Facebook">
              <a
                href="https://www.facebook.com/profile.php?id=61558971922899&mibextid=LQQJ4d"
                target="blank"
              >
                <IconButton>
                  <FacebookIcon />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip title="farrukhclosetofficial@gmail.com">
              <IconButton onClick={sendEmail}>
                <EmailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
