import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import appContext from "state/appContext";
import baseUrl from "url";
const textFieldStyle = {
  backgroundColor: "rgba(0,0,0,0.03)",
  borderRadius: "15px",
  width: "100%",
  border: "0px",
  "& label.Mui-focused": {
    color: "#E52591",
    borderRadius: "15px",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(41, 72, 125)",
    borderRadius: "15px",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "rgb(41, 72, 125)",
    borderRadius: "15px",
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
    "&.Mui-focused fieldset": {
      borderColor: "rgb(41, 72, 125)",
      borderRadius: "15px",
    },
  },
};
export default function Default() {
  const appState = useContext(appContext);
  const { setShowCart, items, getData, setitems, deliveryCharges } = appState;
  const [showLoading, setshowLoading] = useState(false);
  const navigator = useNavigate();
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!values.address) {
      errors.address = "Required";
    }
    if (!values.phoneNo) {
      errors.phoneNo = "Required";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      address: "",
      phoneNo: "",
    },
    validate,
    onSubmit: (values) => {
      createUser(values);
    },
  });
  const updateQuantity = async (newQuantity, index) => {
    try {
      setitems((prevState) => {
        let newState = [...prevState];
        let changeState = { ...newState[index] };
        changeState.quantity = newQuantity;
        newState[index] = changeState;
        return newState;
      });
      await fetch(`${baseUrl}cart/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: items[index]._id,
          quantity: newQuantity,
        }),
      });
    } catch (error) {}
  };
  const totalPrice = () => {
    try {
      let total = 0;
      items.map((item, index) => {
        total += item.productID?.price * item.quantity;
      });
      if(total < 4000 && items.length > 0){
        return total+deliveryCharges
      }
      else{

        return total;
      }
    } catch (error) {}
  };
  const createUser = async (values) => {
    setshowLoading(true);
    try {
      await fetch(`${baseUrl}user/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status) {
            placeOrder(response.user._id);
            formik.values.email = "";
            formik.values.password = "";
            formik.values.name = "";
          } else {
            alert(response.message);
          }
        });
    } catch (error) {
      setshowLoading(false);
    }
  };
  const placeOrder = async (userID) => {
    try {
console.log('here')
      let cartID = [];
      items.map((item) => {
        cartID.push(item._id);
      });
console.log(userID,cartID,totalPrice())
      await fetch(`${baseUrl}order/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartID: cartID,
          userID: userID,
          totalAmount:totalPrice()
        }),
      })
        .then((res) => res.json())
        .then((response) => {
console.log(response);
          if (response.status) {
            navigator("/thank-you-page");
            localStorage.clear("cart");
          }
        });
    } catch (error) {
console.log(error.message)
    } finally {
      setshowLoading(false);
    }
  };
  const removeItem = async (_id) => {
    try {
      await fetch(`${baseUrl}cart/delete?_id=${_id}`, { method: "DELETE" });
      getData();
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {showLoading && (
        <Box
          sx={{
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "1150",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </Box>
      )}
      <Box
        style={{
          marginTop: "140px",
          marginBottom: "40px",
        }}
        onClick={(e) => {
          if (e.target == e.currentTarget) {
            setShowCart(false);
          }
        }}
      >
        <Container>
          <Box>
            <center>
              <Typography
                sx={{
                  marginBottom: "40px",
                }}
                variant="h3"
              >
                Cart
              </Typography>
            </center>
            <Grid container columnSpacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {items.length > 0 ? (
                  items.map((item, index) => (
                    <Card
                      key={index}
                      sx={{
                        margin: "10px 0px",
                        padding: "15px",
                        height: "160px",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <img
                              src={`${baseUrl}${item.productID?.image[0]}`}
                              alt="item"
                              width={"100px"}
                              height={"150px"}
                            />
                          </Box>
                          <Box sx={{ padding: "0px 15px" }}>
                            <Typography variant="h6">
                              {item.productID?.name}
                            </Typography>
                            <Typography variant="h6">
                              Price: {item.productID?.price}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <IconButton disabled={item.quantity == 1}>
                                <RemoveIcon
                                  onClick={() => {
                                    if (item.quantity > 1) {
                                      updateQuantity(item.quantity - 1, index);
                                    }
                                  }}
                                />
                              </IconButton>
                              <Typography
                                sx={{
                                  padding: "4px 7px",
                                  // marginRight: "10px",
                                  cursor: "pointer",
                                }}
                              >
                                {item.quantity}
                              </Typography>
                              <IconButton>
                                <AddIcon
                                  onClick={() => {
                                    if (item.quantity) {
                                      updateQuantity(item.quantity + 1, index);
                                    }
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <IconButton onClick={() => removeItem(item._id)}>
                            <ClearIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Card>
                  ))
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <br />
                    <Typography>No items added</Typography>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <form onSubmit={formik.handleSubmit}>
                  <Typography>Address</Typography>
                  <textarea
                    id="address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    placeholder="Enter Complete Address here"
                    style={{
                      minWidth: "98%",
                      maxWidth: "98%",
                      padding: "10px 1%",
                      backgroundColor: "rgba(0,0,0,0.03)",
                      borderRadius: "16px",
                      // maxWidth:'100%'
                    }}
                  />
                  <label>Full Name</label>
                  <br />
                  <TextField
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    placeholder="Full Name"
                    sx={textFieldStyle}
                  />
                  <label>Email</label>
                  <br />
                  <TextField
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    placeholder="Email"
                    sx={textFieldStyle}
                  />
                  <label>Phone Number</label>
                  <br />
                  <TextField
                    id="phoneNo"
                    name="phoneNo"
                    value={formik.values.phoneNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.phoneNo && Boolean(formik.errors.phoneNo)
                    }
                    helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                    placeholder="Phone Number"
                    sx={textFieldStyle}
                  />
                  <Typography sx={{
                    marginTop:'30px'
                  }} variant="h6">Payment Method</Typography>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom:'30px' }}>
                    <input type="radio" defaultChecked />
                    <Typography
                      sx={{
                        marginLeft: "10px",
                      }}
                    >
                      Cash on Delivery
                    </Typography>
                  </Box>
                  <hr/>
                  {items.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Typography variant="h6">Delivery Charges</Typography>
                      <Typography variant="h6">
                        {totalPrice() < 4000 ? `PKR ${deliveryCharges}` : 'FREE'}
                      </Typography>
                      {/* <br /> */}
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",

                      marginTop: !items.length > 0 && "10px",
                    }}
                  >
                    <Typography variant="h6">Total Price</Typography>
                    <Typography variant="h6">
                      PKR {totalPrice()}
                    </Typography>
                    {/* <br /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: "40px",
                    }}
                  >
                    <Button
                      disabled={items.length < 1}
                      variant="contained"
                      type="submit"
                    >
                      Buy Now
                    </Button>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
