import Footer from "components/footer";
import Navbar from "components/header";
import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import routes from "routes";
import AppState from "state/appState";
import "./App.css";
import { Box } from "@mui/material";
function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);
  return (
    <>
      <AppState>
        <Navbar />
        <Box sx={{minHeight:'80vh', marginTop:'110px'}}>
        <Routes>
          {routes.map((item, index) => (
            <Route
              exact
              path={item.path}
              key={index}
              element={item.component}
            />
          ))}
        </Routes>
        </Box>
        <Footer />
      </AppState>
    </>
  );
}

export default App;
