import { Box, Container } from "@mui/material";
import fiftyOff from "assets/50off.webp";
import banner3 from "assets/Bannar3.jpg";
import banner1 from "assets/banner1.jpg";
import banner2 from "assets/banner2.jpg";
import "react-multi-carousel/lib/styles.css";
import { Carousel as CarouselMain } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-slideshow-image/dist/styles.css";
import AllProducts from "./allProducts";
import FlashSale from "./flashSale";
import TopProducts from "./trendingProducts";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
export default function Default() {
  const navigator = useNavigate();
  return (
    <Box
      sx={{
        marginBottom: "40px",
      }}
    >
      <CarouselMain
        autoPlay
        dynamicHeight
        emulateTouch
        infiniteLoop
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
      >
        <div style={{
          cursor:'pointer'
        }} onClick={() => navigator("/product")}>
          <img
            style={{
              maxHeight: "80vh",
            }}
            src={banner1}
          />
        </div>
        <div style={{
          cursor:'pointer'
        }} onClick={() => navigator("/product")}>
          <img
            style={{
              maxHeight: "80vh",
            }}
            src={banner2}
          />
        </div>
        <div style={{
          cursor:'pointer'
        }} onClick={() => navigator("/product")}>
          <img
            style={{
              maxHeight: "80vh",
            }}
            src={banner3}
          />
        </div>
      </CarouselMain>
      <Container
        sx={{
          marginTop: "40px",
        }}
      >
        <FlashSale />
      </Container>
      <img
        style={{
          width: "98.9vw",
          margin: "40px 0px",
          cursor:'pointer'
        }}
        onClick={() => navigator("/product")}
        src={fiftyOff}
        alt="fiftyOff"
      />
      <Container>
        <TopProducts />
        <AllProducts />
      </Container>
    </Box>
  );
}
export const useIsGridVisible = () => {
  const [isGridVisible, setIsGridVisible] = useState(false);
  const gridRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.2 // 80% visibility threshold
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsGridVisible(entry.isIntersecting);
    }, options);

    if (gridRef.current) {
      observer.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        observer.unobserve(gridRef.current);
      }
    };
  }, []);

  return [isGridVisible, gridRef];
};
