// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  padding:0;
  margin:0;
  font-family: 'Poppins', sans-serif !important;
}
/* .service-card{
  transform: scale(1.0);
  transition: transform 0.3s ease-in-out;
} */

.service-image{
  transform: scale(1.0);
  transition: transform 1s ease-in-out;
}
.service-image:hover{
  transform: scale(1.2);
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(7%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.service-card{
  animation: slideInFromBottom 1s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,QAAQ;EACR,6CAA6C;AAC/C;AACA;;;GAGG;;AAEH;EACE,qBAAqB;EACrB,oCAAoC;AACtC;AACA;EACE,qBAAqB;AACvB;AACA;EACE;IACE,yBAAyB;IACzB,UAAU;EACZ;EACA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":["*{\n  padding:0;\n  margin:0;\n  font-family: 'Poppins', sans-serif !important;\n}\n/* .service-card{\n  transform: scale(1.0);\n  transition: transform 0.3s ease-in-out;\n} */\n\n.service-image{\n  transform: scale(1.0);\n  transition: transform 1s ease-in-out;\n}\n.service-image:hover{\n  transform: scale(1.2);\n}\n@keyframes slideInFromBottom {\n  0% {\n    transform: translateY(7%);\n    opacity: 0;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 1;\n  }\n}\n\n.service-card{\n  animation: slideInFromBottom 1s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
