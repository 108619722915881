import { Box, Typography } from "@mui/material";
import ServiceCard from "components/serviceCard";
import { useContext, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-slideshow-image/dist/styles.css";
import appContext from "state/appContext";
import baseUrl from "url";
import { useIsGridVisible } from "..";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
export default function Default({ foryou }) {
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [isGridVisible, gridRef] = useIsGridVisible();
  const getData = async () => {
    await fetch(`${baseUrl}product/get`)
      .then((res) => res.json())
      .then((response) => {
        if (response.status) setTrendingProducts(response.result);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const appState = useContext(appContext);
  const { addToCart } = appState;
  return (
    <Box ref={gridRef}>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
          },
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "600",
          }}
        >
          {foryou ? "You May Also Like" : "Top Products"}
        </Typography>
      </Box>
      <Box
      className={`${isGridVisible && "service-card"}`}
        sx={{
          padding: "15px",
          backgroundColor: "#00000012",
        }}
      >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          draggable={false}
        >
          {trendingProducts &&
            trendingProducts.map((item, index) => (
              <ServiceCard
                key={index}
                item={item}
                btnText="Add to Cart"
                onClick={() => addToCart(trendingProducts[index]._id)}
              />
            ))}
        </Carousel>
      </Box>
    </Box>
  );
}
