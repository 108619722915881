import { Box, Card, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import appContext from "state/appContext";
import baseUrl from "url";
import { useIsGridVisible } from "views/home";

export default function Default({ item, large, animate }) {
  const navigator = useNavigate();
  const { setDetaileProduct } = useContext(appContext);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isGridVisible, gridRef] = useIsGridVisible();
  return (
    <Card
      ref={gridRef}
      className={`${animate ? (isGridVisible && "service-card") :""}`}
      style={{
        padding: "20px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
      }}
      onClick={() => {
        setDetaileProduct(item);
        navigator(`/product-details?id=${item._id}`);
      }}
    >
      <img
        src={
          item.image[0].includes("upload")
            ? `${baseUrl}${item.image[selectedIndex]}`
            : item.image[0]
        }
        onMouseEnter={() => setSelectedIndex(1)}
        onMouseLeave={() => setSelectedIndex(0)}
        className="service-image"
        width={"100%"}
        height={large ? "300px" : "180px"}
      />
      <Typography
        sx={{
          fontSize: "16px",
          padding: "20px 0px 0px 0px",
        }}
      >
        {item.name}
      </Typography>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
          },
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            padding: {
              xs: "0px 0px 6px 0px",
              sm: "0px 0px 6px 0px",
              md: "0px 0px 20px 0px",
              lg: "0px 0px 20px 0px",
            },
          }}
        >
          PKR: {item.price}
        </Typography>
        {item.comparedPrice && (
          <Typography
            sx={{
              fontSize: "12px",
              padding: "0px 0px 20px 0px",
              textDecoration: "line-through",
              color: "#8B0000",
              marginLeft: {
                xs: "0px",
                sm: "0px",
                md: "12px",
                lg: "12px",
              },
            }}
          >
            PKR: {item.comparedPrice}
          </Typography>
        )}
      </Box>
    </Card>
  );
}
