import { Box, Container, Grid, Typography } from "@mui/material";
import ServiceCard from "components/serviceCard";
import { useContext, useEffect, useState } from "react";
import appContext from "state/appContext";
import baseUrl from "url";
export default function Default() {
  const [services, setservices] = useState([]);
  const getData = async () => {
    await fetch(`${baseUrl}product/get`)
      .then((res) => res.json())
      .then((response) => {
        if (response.status) setservices(response.result);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      sx={{
        paddingTop: "20px",
        margin: {
          xs: "40px 2vw",
          sm: "40px 2vw",
          md: "40px 5vw",
          lg: "40px 5vw",
        },
      }}
    >
      <center>
        <Typography
          style={{
            fontSize: "33px",
            color: "rgb(41, 72, 125)",
            marginBottom: "30px",
          }}
        >
          All Products
        </Typography>
      </center>

      <Container>
        <Grid container spacing={4}>
          {services.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={3} lg={3}>
              <ServiceCard animate={true} large={true} item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
