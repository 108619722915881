import { ShoppingBasket } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Menu, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../App.css";
import Logo from "../../assets/logo.png";
const textFieldStyle = {
  backgroundColor: "rgba(0,0,0,0.03)",
  borderRadius: "15px",
  width: "100%",
  border: "0px",
  "& label.Mui-focused": {
    color: "#E52591",
    borderRadius: "15px",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(41, 72, 125)",
    borderRadius: "15px",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "rgb(41, 72, 125)",
    borderRadius: "15px",
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    borderRadius: "15px",
    "&.Mui-focused fieldset": {
      borderColor: "rgb(41, 72, 125)",
      borderRadius: "15px",
    },
  },
};
export default function Default() {
  const navigator = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [activeSection, setActiveSection] = useState("/");
  const handleClose = () => {
    setAnchorEl(null);
  };
  const location = useLocation();
  useEffect(() => {
    setActiveSection(location.pathname);
  }, [location]);

  return (
    <>
      <Box
        className="background"
        style={{
          position: "fixed", // Stick to the top
          top: 0, // Anchor to the top of the viewport
          left: "0",
          height: "110px",
          background: "#fffffa",
          boxShadow: "0px 0px 16px 0px rgba(0,0,0,0.2)",
          zIndex: "1100",
          width: "100%",
          margin: "0px 0px",
        }}
      >
        <Box
          sx={{
            padding: {
              xs: "0 2vw",
              sm: "0 2vw",
              md: "0 5vw",
              lg: "0 5vw",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                marginTop: "10px",
              }}
            >
              <img
                src={Logo}
                alt={"logo"}
                onClick={()=>navigator("/")}
                style={{
                  width: "210px",
                  cursor:'pointer'
                }}
              />
            </Box>
            {/* <TextField
              placeholder="Search..."
              sx={{
                ...textFieldStyle,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
              }}
            /> */}
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                },
                alignItems: "center",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: activeSection === "/" ? "rgb(41, 72, 125)" : "#525252",
                  padding: "10px 20px",
                }}
              >
                Home
              </Link>
              <Link
                to="/product"
                style={{
                  textDecoration: "none",
                  color:
                    activeSection === "/product"
                      ? "rgb(41, 72, 125)"
                      : "#525252",
                  padding: "10px 20px",
                }}
              >
                Products
              </Link>
              <Link
                to="/contactus"
                style={{
                  textDecoration: "none",
                  color:
                    activeSection === "/contactus"
                      ? "rgb(41, 72, 125)"
                      : "#525252",
                  padding: "10px 20px",
                }}
              >
                Contact
              </Link>
              <ShoppingBasket
                cursor={"pointer"}
                onClick={() => {
                  navigator("/cart");
                }}
              />
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                },
                alignItems: "center",
              }}
            >
              <ShoppingBasket
                cursor={"pointer"}
                sx={{
                  marginRight: "15px",
                }}
                onClick={() => {
                  navigator("/cart");
                }}
              />
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => setShowSearch(!showSearch)}
                sx={{
                  marginRight: "7px",
                }}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Link
                  to="/"
                  onClick={()=>handleClose()}
                  style={{
                    textDecoration: "none",
                    color:
                      activeSection === "/" ? "rgb(41, 72, 125)" : "#525252",
                    padding: "10px 20px",
                    display: "block",
                  }}
                >
                  Home
                </Link>
                <Link
                  to="/product"
                  onClick={()=>handleClose()}
                  style={{
                    textDecoration: "none",
                    color:
                      activeSection === "/services"
                        ? "rgb(41, 72, 125)"
                        : "#525252",
                    padding: "10px 20px",
                    display: "block",
                  }}
                >
                  Products
                </Link>
                <Link
                  to="/contactus"
                  onClick={()=>handleClose()}
                  style={{
                    textDecoration: "none",
                    color:
                      activeSection === "/contactus"
                        ? "rgb(41, 72, 125)"
                        : "#525252",
                    padding: "10px 20px",
                    display: "block",
                  }}
                >
                  Contact Us
                </Link>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
      {showSearch && (
        <Box
          sx={{
            position: "fixed", // Stick to the top
            top: "110px", // Anchor to the top of the viewport
            left: "0",
            height: "65px",
            background: "#fffffa",
            // boxShadow: "0px 0px 16px 0px rgba(0,0,0,0.2)",
            zIndex: "1100",
            width: "100%",
            margin: "0px 0px",
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
            },
          }}
        >
          <Box
            sx={{
              padding: {
                xs: "0 2vw",
                sm: "0 2vw",
                md: "0 5vw",
                lg: "0 5vw",
              },
            }}
          >
            <TextField
              placeholder="Search..."
              sx={{
                ...textFieldStyle,
                marginTop: "5px",
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
