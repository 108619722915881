import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Rating,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Carousel as CarouselMain } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useLocation } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import appContext from "state/appContext";
import baseUrl from "url";
import TrendingProducts from "views/home/trendingProducts";
export default function Default() {
  const location = useLocation();
  const { detaileProduct, addToCart, setDetaileProduct, deliveryCharges } =
    useContext(appContext);
  const [selectedSize, setSelectedSize] = useState(null);
  const [quantity, setQuantity] = useState(1);
  // Extract the query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get the value of a specific query parameter, e.g., 'id'
  const id = queryParams.get("id");
  const getData = async () => {
    await fetch(`${baseUrl}product/getByID?id=${id}`)
      .then((res) => res.json())
      .then((response) => {
        if (response.status) setDetaileProduct(response.result);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#00000012",
        }}
      >
        <Container
          sx={{
            paddingTop: "60px",
            paddingBottom: "50px",
          }}
        >
          <Grid container columnSpacing={6}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {detaileProduct.image?.map((item, index) => (
                <img
                  key={index}
                  style={{
                    height: "120px",
                    width: "80px",
                    marginRight: "10px",
                  }}
                  src={`${baseUrl}${item}`}
                />
              ))}
              <Typography
                sx={{
                  fontSize: "28px",
                  fontWeight: "600",
                }}
              >
                Product Details
              </Typography>
              <div
                dangerouslySetInnerHTML={{ __html: detaileProduct.description }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CarouselMain
                autoPlay
                dynamicHeight
                emulateTouch
                infiniteLoop
                showThumbs={false}
                showIndicators={false}
              >
                {detaileProduct.image &&
                  detaileProduct.image?.map((item, index) => (
                    <div key={index}>
                      <img src={`${baseUrl}${item}`} />
                    </div>
                  ))}
              </CarouselMain>
            </Grid>
            <Grid
              sx={{
                marginTop: {
                  xs: "30px",
                  sm: "30px",
                  md: "0px",
                  lg: "0px",
                },
              }}
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
            >
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "bold",
                }}
              >
                {detaileProduct.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <b>SKU:</b> {detaileProduct.sku}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <b>Brand:</b> Farrukh Closet
              </Typography>
              <hr />
              <Typography
                sx={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginTop: "10px",
                }}
              >
                PKR {detaileProduct.price}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0px",
                }}
              >
                {detaileProduct.comparedPrice && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        textDecoration: "line-through",
                        color: "rgba(0,0,0,0.4)",
                        //   marginLeft: "15px",
                      }}
                    >
                      PKR {detaileProduct.comparedPrice}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: "#8B0000",
                        marginLeft: "10px",
                      }}
                    >
                      {Math.floor(
                        (detaileProduct.price / detaileProduct.comparedPrice) *
                          100 -
                          100
                      )}
                      %
                    </Typography>
                  </>
                )}
              </Box>
              <Box>
                {deliveryCharges != 0 ? (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <b>Delivery:</b> All Over Pakistan
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <b>Delivery:</b> Free Delivery all over Pakistan
                  </Typography>
                )}
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                Size
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {detaileProduct.size && (
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    {detaileProduct.size.map((item, index) => (
                      <Tooltip key={index} title="Select">
                        <Typography
                          sx={{
                            border: `${
                              selectedSize == item ? "2px" : "1px"
                            } solid ${
                              selectedSize == item
                                ? "rgb(41, 72, 125)"
                                : "black"
                            }`,
                            padding: "4px 7px",
                            marginRight: "10px",
                            cursor: "pointer",
                            fontWeight: selectedSize == item && "bold",
                          }}
                          onClick={() => {
                            try {
                              setSelectedSize(item);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          {item}
                        </Typography>
                      </Tooltip>
                    ))}
                  </Box>
                )}
                <a href={`${baseUrl}uploads/Size_Chart.jpg`} download={true} target="blank">Size Chart</a>
              </Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  marginTop: "20px",
                }}
              >
                Quantity
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (quantity > 1) {
                      setQuantity(quantity - 1);
                    }
                  }}
                  disabled={quantity == 1}
                >
                  <RemoveIcon />
                </IconButton>
                <Typography
                  sx={{
                    padding: "4px 7px",
                    cursor: "pointer",
                  }}
                >
                  {quantity}
                </Typography>
                <IconButton
                  disabled={quantity == 2}
                  onClick={() => {
                    if (quantity < 2) {
                      setQuantity(quantity + 1);
                    }
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              {detaileProduct.status == 0 && (
                <Typography
                  sx={{
                    color: "red",
                  }}
                >
                  Sorry this product is currently out of stock.
                </Typography>
              )}
              <Tooltip title="Add To Cart">
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    background: "rgb(41, 72, 125)",
                    marginTop: "20px",
                    borderRadius: "20px",
                    ":& hover": {
                      background: "rgb(41, 72, 125)",
                    },
                  }}
                  disabled={selectedSize == null || detaileProduct.status == 0}
                  onClick={() => {
                    addToCart(detaileProduct._id, selectedSize, quantity);
                  }}
                >
                  Add to cart
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container
        sx={{
          marginTop: "40px",
          marginBottom: "40px",
        }}
      >
        <TrendingProducts foryou={true} />
      </Container>
    </>
  );
}
