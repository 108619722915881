import { Box, Typography } from "@mui/material";
import CountDown from "components/countDown";
import ServiceCard from "components/serviceCard";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-slideshow-image/dist/styles.css";
import baseUrl from "url";
import { useIsGridVisible } from "..";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};
export default function Default() {
  const [flashSale, setFlashSale] = useState([]);
  const [isGridVisible, gridRef] = useIsGridVisible();
  const getData = async () => {
    await fetch(`${baseUrl}product/getSale`)
      .then((res) => res.json())
      .then((response) => {
        if (response.status) setFlashSale(response.result);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div ref={gridRef}>
      <Box
        
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
          },
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "600",
          }}
        >
          Flash Sale
        </Typography>
        <CountDown endTime={new Date("2024-05-31T23:59:59")} />
      </Box>

      <Box
      className={`${isGridVisible && "service-card"}`}
        sx={{
          padding: "15px",
          backgroundColor: "#00000012",
        }}
      >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          draggable={false}
        >
          {flashSale &&
            flashSale.map((item, index) => (
              <ServiceCard key={index} item={item} btnText="Add to Cart" />
            ))}
        </Carousel>
      </Box>
    </div>
  );
}
