import { Box, Button, Card, TextField, Typography } from '@mui/material'
import React from 'react';
const textFieldStyle = {
    backgroundColor:'rgba(0,0,0,0.03)',
    borderRadius:'15px',
    border:'0px',
    "& label.Mui-focused": {
        color: '#E52591',
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: 'rgb(41, 72, 125)'
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: 'rgb(41, 72, 125)'
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: 'rgb(41, 72, 125)'
        }
      }
}
export default function Default(props) {
    const { formik} = props
    return (
        <Card
            style={{
                width: '75%',
                background: 'white',
                borderRadius: '20px',
                padding: '4% 7%',
                marginLeft:'12%'
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Box
                    style={{
                        margin: '15px 0px',
                        display: 'flex',
                        flexDirection: 'column', // Vertically align form elements
                        gap: '5px', // Space between form elements
                    }}
                >
                    <Typography sx={{paddingTop:'10px'}}>Full Name</Typography>
                    <TextField
                        
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        sx={textFieldStyle}
                        InputProps={{
                            style: {
                              borderRadius: "15px",
                            }
                          }}
                    />

                    <Typography sx={{paddingTop:'10px'}}>Email Address</Typography>
                    <TextField
                        
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        sx={textFieldStyle}
                        InputProps={{
                            style: {
                              borderRadius: "15px",
                            }
                          }}
                    />

                    <Typography sx={{paddingTop:'10px'}}>Message</Typography>
                    <TextField
                        fullWidth
                        id="message"
                        name="message"
                        placeholder="Message"
                        multiline
                        rows={4}
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.message && Boolean(formik.errors.message)}
                        helperText={formik.touched.message && formik.errors.message}
                        sx={textFieldStyle}
                        InputProps={{
                            style: {
                              borderRadius: "15px",
                            }
                          }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            background: 'linear-gradient(to left, rgb(61, 92, 155), rgb(41, 72, 125))',
                            borderRadius: '20px',
                            width: '100%',
                            marginTop: '30px',
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </form>
        </Card>
    )
}
