import { Box, Button, Grid, Typography } from "@mui/material";
import ServiceCard from "components/serviceCard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import baseUrl from "url";
import { useIsGridVisible } from "..";

export default function Default() {
  const navigator = useNavigate();
  const [allProducts, setaAllProducts] = useState([]);
  const [isGridVisible, gridRef] = useIsGridVisible();
  const getData = async () => {
    await fetch(`${baseUrl}product/get`)
      .then((res) => res.json())
      .then((response) => {
        if (response.status) setaAllProducts(response.result);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      ref={gridRef}
      sx={{
        marginTop: "30px",
      }}
    >
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "flex",
            lg: "flex",
          },
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "600",
          }}
        >
          All Products
        </Typography>
      </Box>
      <Box
        className={`${isGridVisible && "service-card"}`}
        sx={{
          padding: "15px",
          backgroundColor: "#00000012",
        }}
      >
        <Grid container spacing={2}>
          {allProducts &&
            allProducts.map((item, index) => (
              <Grid item key={index} xs={6} sm={6} md={3} lg={3}>
                <ServiceCard item={item} btnText="Add to Cart" />
              </Grid>
            ))}
        </Grid>
        <center>
          <Button
            onClick={() => navigator("/product")}
            variant="outlined"
            sx={{
              marginTop: "30px",
              color: "#6b8abb",
            }}
          >
            View All
          </Button>
        </center>
      </Box>
    </Box>
  );
}
