import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
const mainBoxStyle = {
  display: "inline-block",
  backgroundColor: {
    xs: "transparent",
    sm: "transparent",
    md: "rgb(41, 72, 125)",
    lg: "rgb(41, 72, 125)",
  },
  border: {
    xs: "1px solid white",
    sm: "1px solid white",
    md: "0px solid white",
    lg: "0px solid white",
  },
  margin: "5px",
  padding: "10px",
  borderRadius: "5px",
  color: "white",
};
const CountdownTimer = ({ endTime }) => {
  const calculateTimeLeft = () => {
    const difference = endTime - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Box
      sx={{
        marginBottom: {
          xs: "30px",
          sm: "30px",
          md: "0px",
          lg: "0px",
        },
        display: "flex",
        justifyContent: "center",
        backgroundColor: {
          xs: "rgb(41, 72, 125)",
          sm: "rgb(41, 72, 125)",
          md: "transparent",
          lg: "transparent",
        },
      }}
    >
      {timeLeft.days ? <Box sx={mainBoxStyle}>{timeLeft.days} Days</Box> : null}
      {timeLeft.hours ? (
        <Box sx={mainBoxStyle}>{timeLeft.hours} Hours</Box>
      ) : null}
      {timeLeft.minutes ? (
        <Box sx={mainBoxStyle}>{timeLeft.minutes} Minutes</Box>
      ) : null}
      {timeLeft.seconds ? (
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "inline-block",
              lg: "inline-block",
            },
            backgroundColor: "rgb(41, 72, 125)",
            color: "white",
            margin: "5px",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          {timeLeft.seconds} Seconds
        </Box>
      ) : null}
    </Box>
  );
};

export default CountdownTimer;
