import ContactUs from "views/contactUs";
import Home from "views/home";
import Products from "views/products";
import ProductDetails from "views/productPage";
import Cart from "views/cartPopup";
import ThankYou from "views/orderPlaced";
const routes = [
  {
    path: "/",
    name: "Home",
    component: <Home />,
  },
  {
    path: "/contactus",
    name: "Contact us",
    component: <ContactUs />,
  },
  {
    path: "/product",
    name: "Product",
    component: <Products />,
  },
  {
    path: "/product-details",
    name: "Product Details",
    component: <ProductDetails />,
  },
  {
    path: "/cart",
    name: "Cart",
    component: <Cart />,
  },
  {
    path: "/thank-you-page",
    name: "Thank you page",
    component: <ThankYou />,
  },
];
export default routes;
