import { useEffect } from "react";
import tickImage from "assets/download.png";
const ThankYouPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/';
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <>
        <img style={{
          maxHeight:'200px',
          marginBottom:'100px',
          marginTop:'70px'
        }} src={tickImage} alt="tickImage" />
        <h1>Thank you for your order!</h1>
        <p>
          We appreciate your business and will get your order processed as soon
          as possible.
        </p>
        <p>You will be redirected to the homepage in 5 seconds.</p>
      </>
    </div>
  );
};

export default ThankYouPage;
