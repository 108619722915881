import React, { useState } from "react";
import AppContext from "./appContext";
import baseUrl from "url";
export default function AppState(props) {
  const [showSignup, setShowSignup] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [detaileProduct, setDetaileProduct] = useState({});
  const [deliveryCharges, setDeliveryCharges] = useState(200);
  const [items, setitems] = useState([]);
  const getData = async () => {
    await fetch(`${baseUrl}cart/getUserCart`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cartID: localStorage.getItem("cart")
          ? JSON.parse(localStorage.getItem("cart"))
          : [],
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          setitems(response.result);
        } else {
          setitems([]);
        }
      });
  };
  const addToCart = async (productID, selectedSize, quantity) => {
    await fetch(`${baseUrl}cart/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productID: productID,
        selectedSize: selectedSize,
        quantity: quantity,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status) {
          alert("Item added to cart");
          let currentCart = [];
          try {
              // Check if there's an existing cart in localStorage
              if (localStorage.getItem("cart")) {
                  currentCart = JSON.parse(localStorage.getItem("cart"));
              }
              // Access the _id property of response.result safely
              const itemId = response.result && response.result._id;
              if (itemId) {
                  currentCart.push(itemId);
                  // Store the updated cart in localStorage
                  localStorage.setItem("cart", JSON.stringify(currentCart));
              } else {
                  console.error("Item ID not found in the response result.");
              }
          } catch (error) {
              console.error("Error occurred while parsing or storing the cart:", error);
          }
      }
      });
  };
  return (
    <AppContext.Provider
      value={{
        showSignup,
        setShowSignup,
        showLogin,
        items,
        setitems,
        getData,
        setShowLogin,
        showCart,
        setShowCart,
        addToCart,
        detaileProduct,
        setDetaileProduct,
        deliveryCharges,
        setDeliveryCharges
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
